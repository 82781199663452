import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Em, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				FocusFrame Rentals
			</title>
			<meta name={"description"} content={"У FocusFrame Rentals ми надаємо вам високоякісні інструменти та простори, щоб втілити ваше бачення в життя."} />
			<meta property={"og:title"} content={"FocusFrame Rentals"} />
			<meta property={"og:description"} content={"У FocusFrame Rentals ми надаємо вам високоякісні інструменти та простори, щоб втілити ваше бачення в життя."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section
			padding="140px 0 140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(120deg,rgba(0,0,0,.2) 0%,rgba(16, 1, 1, 0.73) 100%),--color-dark url(https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z) top/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="20px"
					text-transform="uppercase"
					font="--headline3"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Розкрийте свій творчий потенціал
				</Text>
			</Box>
			<Box
				display="flex"
				margin="-16px -16px -16px -16px"
				flex-wrap="wrap"
				width="100%"
				justify-content="space-between"
			>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px" font="--lead" text-transform="uppercase">
							Ласкаво просимо до вашого кінцевого місця для фотографій і відео!
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							У FocusFrame Rentals ми надаємо вам високоякісні інструменти та простори, щоб втілити ваше бачення в життя.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			/>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Text
					font="--headline3"
					lg-text-align="center"
					margin="16px 0px 24px 0px"
					color="--grey"
					md-text-align="left"
				>
					Ми пишаємося тим, що ми є провідним постачальником фотообладнання та прокату студій.{" "}
				</Text>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-4.jpg?v=2024-06-11T09:10:49.088Z"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-4.jpg?v=2024-06-11T09%3A10%3A49.088Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-4.jpg?v=2024-06-11T09%3A10%3A49.088Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-4.jpg?v=2024-06-11T09%3A10%3A49.088Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-4.jpg?v=2024-06-11T09%3A10%3A49.088Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-4.jpg?v=2024-06-11T09%3A10%3A49.088Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-4.jpg?v=2024-06-11T09%3A10%3A49.088Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-4.jpg?v=2024-06-11T09%3A10%3A49.088Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-3.jpg?v=2024-06-11T09:10:49.106Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-3.jpg?v=2024-06-11T09%3A10%3A49.106Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-3.jpg?v=2024-06-11T09%3A10%3A49.106Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-3.jpg?v=2024-06-11T09%3A10%3A49.106Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-3.jpg?v=2024-06-11T09%3A10%3A49.106Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-3.jpg?v=2024-06-11T09%3A10%3A49.106Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-3.jpg?v=2024-06-11T09%3A10%3A49.106Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-3.jpg?v=2024-06-11T09%3A10%3A49.106Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Завдяки широкому вибору найновіших камер, об’єктивів, освітлювальних приладів і аксесуарів, а також різноманітних студійних приміщень, ми обслуговуємо всі типи фото- та відеографічних робіт.{" "}
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-2.jpg?v=2024-06-11T09:10:49.102Z"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						srcSet="https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-2.jpg?v=2024-06-11T09%3A10%3A49.102Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-2.jpg?v=2024-06-11T09%3A10%3A49.102Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-2.jpg?v=2024-06-11T09%3A10%3A49.102Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-2.jpg?v=2024-06-11T09%3A10%3A49.102Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-2.jpg?v=2024-06-11T09%3A10%3A49.102Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-2.jpg?v=2024-06-11T09%3A10%3A49.102Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-2.jpg?v=2024-06-11T09%3A10%3A49.102Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Наше зобов’язання полягає в тому, щоб забезпечити вам доступ до найкращих ресурсів без клопоту витрат на володіння.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box />
				<Text margin="0px 0px 0px 0px" color="--grey" font="--headline3" lg-text-align="center">
					<Em>
						Ми пропонуємо більше, ніж просто обладнання – ми надаємо місце для творчості. Наші зручності включають повністю обладнані студії, зручні кімнати для клієнтів і технічну підтримку на місці, що гарантує, що у вас є все необхідне для успішної зйомки. Незалежно від того, чи це модна зйомка, каталог продукції чи креативний кінопроект, наші приміщення адаптуються відповідно до ваших конкретних потреб.
					</Em>
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-1.jpg?v=2024-06-11T09:10:49.105Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-1.jpg?v=2024-06-11T09%3A10%3A49.105Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-1.jpg?v=2024-06-11T09%3A10%3A49.105Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-1.jpg?v=2024-06-11T09%3A10%3A49.105Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-1.jpg?v=2024-06-11T09%3A10%3A49.105Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-1.jpg?v=2024-06-11T09%3A10%3A49.105Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-1.jpg?v=2024-06-11T09%3A10%3A49.105Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-1.jpg?v=2024-06-11T09%3A10%3A49.105Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-1.jpg?v=2024-06-11T09:10:49.083Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-1.jpg?v=2024-06-11T09%3A10%3A49.083Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-1.jpg?v=2024-06-11T09%3A10%3A49.083Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-1.jpg?v=2024-06-11T09%3A10%3A49.083Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-1.jpg?v=2024-06-11T09%3A10%3A49.083Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-1.jpg?v=2024-06-11T09%3A10%3A49.083Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-1.jpg?v=2024-06-11T09%3A10%3A49.083Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-1.jpg?v=2024-06-11T09%3A10%3A49.083Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Чому обирають нас?
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					Передове обладнання:
			 Завжди знімайте з найсучаснішою технологією під рукою.{"\n"}
					<br />
					<br />
					{"\n"}Універсальні студійні простори: 
 від налаштувань природного освітлення до фону, який можна налаштувати, наші студії адаптуються до ваших творчих потреб.{"\n"}
					<br />
					<br />
					{"\n"}Підтримка експертів:
 Наші досвідчені технічні спеціалісти допоможуть вам у налаштуванні та усуненні несправностей.{"\n\n"}
					<br />
					<br />
					Гучкі умови оренди:
 ми пропонуємо конкурентоспроможні ціни та гнучкий графік бронювання відповідно до графіку вашого проекту.
				</Text>
				<Button margin="2rem 0px 0px 0px" type="link" href="/services">
					Наші послуги
				</Button>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Забронюйте сеанс зараз
					</Text>
				</Box>
				<Text margin="0px 0px 2rem 0px" color="--grey" font="--lead" lg-text-align="center">
					Розвивайте свої творчі проекти за допомогою FocusFrame Rentals, де ваша уява поєднується з нашим досвідом.
				</Text>
				<Button margin="2rem 0px 0px 0px" type="link" href="tel:0689304313">
					Забронювати
				</Button>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-4.jpg?v=2024-06-11T09:10:49.100Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-4.jpg?v=2024-06-11T09%3A10%3A49.100Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-4.jpg?v=2024-06-11T09%3A10%3A49.100Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-4.jpg?v=2024-06-11T09%3A10%3A49.100Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-4.jpg?v=2024-06-11T09%3A10%3A49.100Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-4.jpg?v=2024-06-11T09%3A10%3A49.100Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-4.jpg?v=2024-06-11T09%3A10%3A49.100Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666dead8e2e8e00217cb7da/images/2-4.jpg?v=2024-06-11T09%3A10%3A49.100Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link3">
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Bohdana Khmel'nyts'koho St, 188А,{" "}
					<br />
					Lviv, Lviv Oblast, 79000
					<br />
					<br />
					0689304313
				</Em>
			</Override>
			<Override slot="link1" />
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});